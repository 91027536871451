<!-- 文件中文名: 游轮游信息保存-->
<template>
  <div class="CruiseInformationSave">
    <app-breadcrumb/>
    <div class="page-container">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="auto" size="mini">
        <el-form-item label="团队:" prop="team">
          {{ ruleForm.team }}
        </el-form-item>
        <el-form-item label="代办处编号:" prop="agentNo">
          {{ ruleForm.agentNo }}
        </el-form-item>
        <el-form-item label="代办处姓名:" prop="agentName">
          {{ ruleForm.agentName }}
        </el-form-item>
        <el-form-item label="资格人姓名:" prop="qualifiedName">
          {{ ruleForm.qualifiedName }}
        </el-form-item>
        <el-form-item label="资格人编号:" prop="qualifiedNo">
          <el-input ref="qualifiedNoIpt" v-model.trim="ruleForm.qualifiedNo" placeholder="请填写经销商编号" style="width:195px;" :disabled="formParams.status==='view'||memberDisable"></el-input>
          <span :style="ruleForm.canUseNum>0?'color: green':'color: red'"> {{ ruleForm.canUseNum }}</span>
        </el-form-item>
        <el-form-item label="是否转让:" prop="isTransfer">
          <el-radio-group v-model="ruleForm.isTransfer" @change="isTransferChange">
            <el-radio label="0" :disabled="formParams.status==='view'">否</el-radio>
            <el-radio label="1" :disabled="formParams.status==='view'">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.isTransfer==='1'" label="上传转让申请图片:" prop="transferphotoUrl">
          <el-upload
              :disabled="formParams.status==='view'"
              :action="uploadUrl"
              name="multipartFile"
              :limit="1"
              multiple
              list-type="picture-card"
              :headers="headers"
              :file-list="fileList1"
              :before-upload="beforeUpload"
              :on-success="handleUploadSuccess1"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove1">
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip" style="margin:0;">只能上传jpg/png文件，且不超过5MB</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="出行人身份证号:" prop="idCard">
          <el-input v-model.trim="ruleForm.idCard" placeholder="请填写身份证号" style="width:195px;"
                    :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <el-form-item label="上传出行人身份证图片(正反两张):" required>
          <el-upload
              :disabled="formParams.status==='view'"
              :action="uploadUrl"
              name="multipartFile"
              :limit="2"
              multiple
              list-type="picture-card"
              :headers="headers"
              :file-list="fileList2"
              :before-upload="beforeUpload"
              :on-success="handleUploadSuccess2"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove2">
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip" style="margin:0;">只能上传jpg/png文件，且不超过5MB</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="出行人编号:" prop="memberNo">
          <el-input v-model.trim="ruleForm.memberNo" placeholder="请填写出行人编号" style="width:195px;"
                    :disabled="formParams.status==='view'||ruleForm.isTransfer==='0'" @blur="checkMemberNo"></el-input>
          <span :style="checkMemberNoStr==='x' ? 'color: red': 'color: green'"> {{ checkMemberNoStr }}</span>
        </el-form-item>
        <el-form-item label="出行人姓:" prop="familyName">
          <el-input v-model.trim="ruleForm.familyName" placeholder="请填写姓" style="width:195px;"
                    :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <el-form-item label="出行人名:" prop="name">
          <el-input v-model.trim="ruleForm.name" placeholder="请填写名" style="width:195px;"
                    :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <el-form-item label="出行人姓名:" prop="memberName">
          {{ '' + ruleForm.familyName + ruleForm.name }}
        </el-form-item>
        <el-form-item label="出行人拼音姓:" prop="pinyinXing">
          <el-input v-model.trim="ruleForm.pinyinXing" placeholder="请填写英文大写字母拼音"
                    style="width:195px;"
                    :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <el-form-item label="出行人拼音名:" prop="pinyinMing">
          <el-input v-model.trim="ruleForm.pinyinMing" placeholder="请填写英文大写字母拼音"
                    style="width:195px;"
                    :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <el-form-item label="出行人性别:" prop="sex">
          <el-radio-group v-model="ruleForm.sex">
            <el-radio label="1" :disabled="formParams.status==='view'">男</el-radio>
            <el-radio label="0" :disabled="formParams.status==='view'">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="出行人出生年月:" prop="born">
          <el-date-picker
              :disabled="formParams.status==='view'"
              v-model="ruleForm.born"
              value-format="yyyy/MM/dd"
              type="date"
              size="mini"
              style="width:195px;"
              placeholder="请填写出生年月">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="出行人出生地:" prop="bornPlace">
          <el-input v-model.trim="ruleForm.bornPlace" placeholder="请填写英文大写字母拼音"
                    style="width:195px;" :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <el-form-item label="出行人手机号:" prop="mobile">
          <el-input v-model.trim="ruleForm.mobile" placeholder="请填写手机号" style="width:195px;" :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <el-form-item label="上传出行人护照图片:" required>
          <el-upload
              :disabled="formParams.status==='view'"
              :action="uploadUrl"
              name="multipartFile"
              :limit="1"
              multiple
              list-type="picture-card"
              :headers="headers"
              :file-list="fileList3"
              :before-upload="beforeUpload"
              :on-success="handleUploadSuccess3"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove3">
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip" style="margin:0;">只能上传jpg/png文件，且不超过5MB</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="出行人护照号码:" prop="passport">
          <el-input v-model.trim="ruleForm.passport" placeholder="请填写户籍号码" style="width:195px;" :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <!--<el-form-item label="出行人国家码:" prop="nationality">
          <el-input v-model.trim="ruleForm.nationality" placeholder="请填写国家码" style="width:195px;" :disabled="formParams.status==='view'"></el-input>
        </el-form-item>-->
        <el-form-item label="出行人护照签发地:" prop="passportPlace">
          <el-input v-model.trim="ruleForm.passportPlace" placeholder="请填写护照签发地"
                    style="width:195px;" :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <el-form-item label="出行人护照签发日期:" prop="issueDate">
          <el-date-picker
              :disabled="formParams.status==='view'"
              v-model="ruleForm.issueDate"
              value-format="yyyy/MM/dd"
              type="date"
              size="mini"
              style="width:195px;"
              placeholder="请填写签发日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="出行人护照有效期:" prop="stopDate">
          <el-date-picker
              :disabled="formParams.status==='view'"
              v-model="ruleForm.stopDate"
              value-format="yyyy/MM/dd"
              type="date"
              size="mini"
              style="width:195px;"
              :picker-options="{disabledDate}"
              :default-value="new Date(2024, 11, 25)"
              placeholder="请填写有效期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="房型:" prop="roomType">
          <el-input v-model.trim="ruleForm.roomType" placeholder="请填写房型" style="width:195px;" :disabled="formParams.status==='view'||!isAgent"></el-input>
        </el-form-item>
        <el-form-item label="同住人:" prop="residents">
          <el-input v-model.trim="ruleForm.residents" placeholder="请填写同住人" style="width:195px;" :disabled="formParams.status==='view'||!isAgent"></el-input>
        </el-form-item>
        <el-form-item label="房号:" prop="roomNo">
          <el-input v-model.trim="ruleForm.roomNo" placeholder="请填写房号" style="width:195px;" :disabled="formParams.status==='view'||!isAgent"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="default" icon="el-icon-close" @click="onBack">返回</el-button>
          <el-button :loading="doLoading" type="primary" icon="el-icon-check" @click="onSave" v-if="formParams.status!=='view'">保存</el-button>
        </el-form-item>
      </el-form>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
  </div>
</template>

<script>

import {getToken} from "@/utils/auth";
import {memberPassportMemberInfo, memberPassportSave, memberPassportViewById} from "@/api/mi/memberPassport";

import {mapGetters} from 'vuex'
import {findMemberInfo} from "@/api/common/common";

export default {
  name: 'CruiseInformationSave',
  // import引入的组件需要注入到对象中才能使用",
  components: {},
  // 作为子组件传入数据
  props: {
    typeNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    const checkUpperCase = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('不能为空'));
      }
      // 正则表达式，匹配大写英文字母
      const regex = /^[A-Z]+$/;
      // 使用 test 方法检查字符串是否匹配正则表达式
      if (regex.test(value)) {
        callback();
      } else {
        callback(new Error('请输入大写英文字母拼音'))
      }
    };
    return {
      editData: null,
      checkMemberNoStr: '',
      memberDisable: false,
      formParams: {
        status: null,
      },
      fileList1: [],
      fileList2: [],
      fileList3: [],
      dialogImageUrl: '',
      dialogVisible: false,
      doLoading: false,
      uploadPercent: 0,
      picFileName: '',
      uploadUrl: process.env.VUE_APP_BASE_API + '/miPicture/miPictureUpload',
      headers: {
        'Authorization': getToken()
      },
      active: 0,
      ruleForm: {
        canUseNum: '',
        memberNo: '',
        agentName: '',
        qualifiedName: '',
        agentNo: '',
        team: '',
        qualifiedNo: '',
        isTransfer: '',
        familyName: '',
        name: '',
        idCard: '',
        pinyinXing: '',
        pinyinMing: '',
        sex: '',
        born: '',
        bornPlace: '',
        mobile: '',
        passport: '',
        nationality: '',
        passportPlace: '',
        issueDate: '',
        stopDate: '',
        roomType: '',
        residents: '',
        roomNo: '',
      },
      rules: {
        qualifiedNo: [
          {required: true, message: '请填写10位资格人编号', len: 10, trigger: ['change', 'blur']},
          {validator: this.checkQualifiedNo, trigger: 'blur'}
        ],
        memberNo: [
          {message: '请填写10位出行人编号', len: 10, trigger: ['change', 'blur']}
        ],
        isTransfer: [{required: true, message: '请选择是否转让', trigger: ['change', 'blur']}],
        familyName: [{required: true, message: '请填写中文姓', pattern: /^[\u4e00-\u9fa5]+$/,trigger: ['change', 'blur']}],
        name: [{required: true, message: '请填写中文名', pattern: /^[\u4e00-\u9fa5]+$/, trigger: ['change', 'blur']}],
        idCard: [{required: true, message: '请填写18位身份证号', len: 18, trigger: ['change', 'blur']}],
        pinyinXing: [
          {required: true, message: '请填写拼音姓,只支持英文大写字母拼音', trigger: ['change', 'blur']},
          {validator: checkUpperCase, trigger: 'blur'}
        ],
        pinyinMing: [
          {required: true, message: '请填写拼音名,只支持英文大写字母拼音', trigger: ['change', 'blur']},
          {validator: checkUpperCase, trigger: 'blur'}
        ],
        sex: [{required: true, message: '请选择性别', trigger: ['change', 'blur']}],
        born: [{required: true, message: '请填写出生日期', trigger: ['change', 'blur']}],
        bornPlace: [
          {required: true, message: '请填写英文大写字母拼音', trigger: ['change', 'blur']},
          {validator: checkUpperCase, trigger: 'blur'}
        ],
        passportPlace: [
          {required: true, message: '请填写英文大写字母拼音', trigger: ['change', 'blur']},
          {validator: checkUpperCase, trigger: 'blur'}
        ],
        mobile: [{required: true, message: '请填写11位手机号', pattern: /^1\d{10}$/, trigger: ['change', 'blur']}],
        passport: [{required: true, message: '请填写9位护照号码', len: 9, trigger: ['change', 'blur']}],
        // nationality: [{required: true, message: '请填写国家码', trigger: ['change', 'blur']}],
        issueDate: [{required: true, message: '请填写签发日期', trigger: ['change', 'blur']}],
        stopDate: [{required: true, message: '请填写有效期', trigger: ['change', 'blur']}],
      }
    }
  },
  computed: {
    ...mapGetters([
      'isAgent',
      'userCode',
    ]),
  },
  watch: {},
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {

  },

  // 生命周期 - 创建完成（可以访问当前this实例）",
  // 数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  async created() {
    this.formParams = sessionStorage.getItem('CruiseInfo') ? JSON.parse(sessionStorage.getItem('CruiseInfo')) : {}
    // console.log(this.formParams)
    if (this.formParams.status !== 'new') {
      let ViewEdit = await this.getViewEditData(this.formParams.id)
      // console.log(JSON.parse(JSON.stringify(ViewEdit)), 'ViewEdit')
      if(this.formParams.status === 'edit') {
        this.editData = JSON.parse(JSON.stringify(ViewEdit))
      }
      if (ViewEdit.isTransfer === '1') {
        this.fileList1.push({
          name: ViewEdit.transferphoto,
          url: ViewEdit.transferphotoUrl,
          response: {data: {fileName: ViewEdit.transferphoto, url: ViewEdit.transferphotoUrl}}
        })
      }
      this.fileList2.push({
        name: ViewEdit.idphotoFront,
        url: ViewEdit.idphotoFrontUrl,
        response: {data: {fileName: ViewEdit.idphotoFront, url: ViewEdit.idphotoFrontUrl}}
      })
      this.fileList2.push({
        name: ViewEdit.idphotoBack,
        url: ViewEdit.idphotoBackUrl,
        response: {data: {fileName: ViewEdit.idphotoBack, url: ViewEdit.idphotoBackUrl}}
      })
      this.fileList3.push({
        name: ViewEdit.passportphoto,
        url: ViewEdit.passportphotoUrl,
        response: {data: {fileName: ViewEdit.passportphoto, url: ViewEdit.passportphotoUrl}}
      })
      this.ruleForm = ViewEdit
      if(!this.isAgent) {
        this.memberDisable = true
      }
    } else {
      //如果是新增,并且是经销商登录,资格人为经销商本人
      if (!this.isAgent) {
        this.ruleForm.qualifiedNo = this.userCode
        this.$nextTick(()=>{
          this.$refs.qualifiedNoIpt.focus()
          this.$refs.qualifiedNoIpt.blur()
          this.memberDisable = true
        })
      }
      console.log(this.isAgent, this.userCode)
    }

  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {

  },

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {

  },

  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  // 生命周期 - 销毁之前",
  beforeDestroy() {

  },
  // 生命周期 - 销毁完成",
  destroyed() {

  },
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  },
  // 方法集合",
  methods: {
    disabledDate(time) {
      let date = new Date(2024, 11, 25)
      return time.getTime() < date.getTime()
    },
    isTransferChange() {
      if(this.ruleForm.isTransfer === '0') {
        this.ruleForm.memberNo =  this.ruleForm.qualifiedNo
        this.checkMemberNo()
      } else {
        this.ruleForm.memberNo = ''
        this.checkMemberNoStr = ''
      }
    },
    checkQualifiedNo(rule, value, callback) {
      if (!value) {
        return callback(new Error('不能为空'));
      }
      memberPassportMemberInfo({memberNo: value}).then(res => {
        console.log(res.data)
        this.ruleForm.canUseNum = res.data.qualificationNum
        this.ruleForm.agentNo = res.data.agentNo
        this.ruleForm.agentName = res.data.agentName
        this.ruleForm.qualifiedName = res.data.memberName
        this.ruleForm.team = res.data.miTeam.teamName
        if (res.data.qualificationNum > 0) {
          callback()
        } else {
          if(this.formParams?.status === 'edit') {
            callback()
          } else {
            callback(new Error('此经销商已无资格!'))
          }
        }
      }).catch(err => {
        callback(new Error('请求出错!'))
        console.error(err)
      })
    },
    checkMemberNo() {
      if (!this.ruleForm.memberNo) {
        this.checkMemberNoStr = ''
        return false
      }
      findMemberInfo({code: this.ruleForm.memberNo}).then(res => {
        console.log(res.data, 'checkMemberNo')
        this.checkMemberNoStr = res.data.memberName
      }).catch(err => {
        this.checkMemberNoStr = 'x'
        console.error(err)
      })
    },
    beforeUpload(file) {
      const acceptTypes = ['image/png', 'image/jpeg']
      const isJPG = acceptTypes.some(item => item === file.type)
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传图片只能是jpg/png格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过5MB')
        return false
      }
      return isJPG && isLt2M
    },
    handleUploadSuccess1(response, file, fileList) {
      console.log(response, 'handleUploadSuccess1')
      this.fileList1 = fileList
    },
    handleRemove1(file, fileList) {
      this.fileList1 = fileList
    },
    handleUploadSuccess2(response, file, fileList) {
      console.log(response, 'handleUploadSuccess2')
      this.fileList2 = fileList
    },
    handleRemove2(file, fileList) {
      this.fileList2 = fileList
    },
    handleUploadSuccess3(response, file, fileList) {
      console.log(response, 'handleUploadSuccess3')
      this.fileList3 = fileList
    },
    handleRemove3(file, fileList) {
      this.fileList3 = fileList
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    onSave() {
      this.ruleForm.memberName = this.ruleForm.familyName + this.ruleForm.name
      //转让申请图片链接
      if (this.ruleForm.isTransfer === '1') {
        const transferphotoUrl = this.fileList1[0]?.response?.data?.fileName
        if (!transferphotoUrl) {
          this.$message.error('请上传转让申请图片')
          return
        }
        this.ruleForm.transferphoto = transferphotoUrl
      }
      //身份证正反图片链接
      this.ruleForm.idphotoFront = this.fileList2[0]?.response?.data?.fileName
      this.ruleForm.idphotoBack = this.fileList2[1]?.response?.data?.fileName
      //护照图片链接
      this.ruleForm.passportphoto = this.fileList3[0]?.response?.data?.fileName

      if (!this.ruleForm.idphotoFront || !this.ruleForm.idphotoBack || !this.ruleForm.passportphoto) {
        return this.$message.error('请上传身份证正反图片或者护照图片')
      }
      // console.log(this.ruleForm, 'this.ruleForm')
      // ----编辑状态判断是否进行修改开始----
      if(this.formParams.status === 'edit') {
        const keys1 = Object.keys(this.ruleForm)
        let isSame = true
        for (let key of keys1) {
          const val1 = this.ruleForm[key]
          const val2 = this.editData[key]
          if(val1!==val2) {
            console.log(key, val1, val2)
            isSame = false
          }
        }
        if(isSame) {
          this.$message.error('您没有修改任何信息!')
          return
        }
      }
      // ----编辑状态判断是否进行修改结束----


      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$confirm('确定提交信息?', '信息录入', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'success'
          }).then(() => {
            this.doLoading = true
            memberPassportSave({...this.ruleForm}).then((res) => {
              this.doLoading = false
              console.log(res, 'res')
              if (res.success) {
                this.$message.success('录入成功')
                this.$router.go(-1)
              }
            }).catch(err => {
              console.warn(err)
              this.doLoading = false
            })
          })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '操作取消'
                })
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    onBack() {
      this.$router.go(-1)
    },
    getViewEditData(id) {
      return new Promise((resolve, reject) => {
        this.doLoading = true
        memberPassportViewById({id}).then((res) => {
          this.doLoading = false
          resolve(res.data)
        }).catch(err => {
          this.doLoading = false
          console.warn(err)
          reject(err)
        })
      })
    }
  }
}

</script>

<style scoped>

</style>
