import request from '@/utils/request'

// 游轮信息管理 分页 前台
export function memberPassportPageByDTO(query) {
  return request({
    url: '/memberPassport/pageByDTO',
    method: 'get',
    params: query
  })
}

// 游轮信息管理 集合 前台
export function listByDTO(query) {
  return request({
    url: '/memberPassport/listByDTO',
    method: 'get',
    params: query
  })
}

// 游轮信息管理 查看 前台
export function memberPassportViewById(query) {
  return request({
    url: '/memberPassport/viewById',
    method: 'get',
    params: query
  })
}

// 游轮信息管理 删除 前台
export function memberPassportDropById(query) {
  return request({
    url: '/memberPassport/dropById',
    method: 'get',
    params: query
  })
}

// 游轮信息管理 更新 前台
export function memberPassportSave(data) {
  return request({
    url: '/memberPassport/save',
    method: 'post',
    data
  })
}

// 游轮信息管理 审核 前台
export function check(query) {
  return request({
    url: '/memberPassport/check',
    method: 'get',
    params: query
  })
}

// 经销商游轮信息验证 前台
export function memberPassportMemberInfo(query) {
  return request({
    url: '/memberPassport/memberInfo',
    method: 'get',
    params: query
  })
}

// 经销商游轮信息管理 获取代办处剩余资格数
export function checkNumberQual(query) {
  return request({
    url: '/memberPassport/checkNumberQual',
    method: 'get',
    params: query
  })
}
